@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* For hsl(60 9% 98% / 1) */
    --background-light: 60 9% 98%;
    /* For hsl(72 11% 97% / 1) */
    --background: 72 11% 97%;
    /* For hsl(72 11% 91% / 1) */
    --background-dark: 72 11% 91%;
    /* For hsl(75 9% 9% / 1) */
    --foreground: 75 9% 9%;
    /* For hsl(72 100% 50% / 1); */
    --primary: 72 100% 50%;
    /* For hsl(72 2% 58% / 1) */
    --grey: 72 2% 58%;
    /* For hsl(348 85% 54% / 1) */
    --error: 348 85% 54%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0f1117;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-item-selected {
  background: #0f1117 !important;
}

.react-pdf__Document {
  --scale-factor: 0 !important;
  display: flex;
  justify-content: center;
}

.react-pdf__Page {
  --scale-factor: 0 !important;
}

.react-pdf__Page__canvas {
  max-width: 100%;
}

.ant-select-arrow {
  color: white !important;
  /* color: #ceff00 !important;  */
}

.ant-input:focus {
  border-color: #ceff00;
  box-shadow: 0 0 0 2px #ceff00 !important;
  /* Optional: if you want to keep the shadow effect */
}

.condukt-green {
  color: #ceff00;
}

.centered {
  display: flex;
  justify-content: center;
}

.order-list-item .ant-list-item-action {
  display: flex;
  justify-content: center;
  align-items: center;
}

#documents-table tbody tr {
  background: #2a2b34;
}
